/** @format */

import axios from "axios";

// export const BASE_URL = "http://172.190.50.86:4000";

let AllEnvApiUrls = {
  production: {
    baseUrl: process.env.REACT_APP_PROD_API_URL ,
  },
  staging: {
    baseUrl: process.env.REACT_APP_STAG_API_URL ,
  },
  development: {
    baseUrl: process.env.REACT_APP_DEV_API_URL ,
  },
};

const ApiUrls = AllEnvApiUrls[process.env.REACT_APP_APP_ENVIRONMENT] || "http://localhost:4000";

// console.log(ApiUrls, "ApiUrlsApiUrlsApiUrlsApiUrlsApiUrls");

export const API_URLS = {
  sign_up: `${ApiUrls.baseUrl}/v1/auth/register`,
  sign_in: `${ApiUrls.baseUrl}/v1/auth/login`,
  forgot_password: `${ApiUrls.baseUrl}/v1/auth/forgot-password`,
  reset_password: `${ApiUrls.baseUrl}/v1/auth/reset-password`,
  update_profile: `${ApiUrls.baseUrl}/v1/users/userprofile`,
  connect_shopify: `${ApiUrls.baseUrl}/v1/shopify/install`,
  getUserProfile: `${ApiUrls.baseUrl}/v1/users/getuserprofile`,
  my_details: `${ApiUrls.baseUrl}/v1/users/userprofile`,
  send_email: `${ApiUrls.baseUrl}/v1/auth/send-verification-email`,
  verify_Email: `${ApiUrls.baseUrl}/v1/auth/verify-email`,
  change_passWord: `${ApiUrls.baseUrl}/v1/auth/change-password`,
  check_status: `${ApiUrls.baseUrl}/v1/shopify/shopConnectionStatus`,
  disconnect: `${ApiUrls.baseUrl}/v1/shopify/disconnectShop`,
  connectionstatus: `${ApiUrls.baseUrl}/v1/shopify/shopConnectionWithEmail`,
  customercreate: `${ApiUrls.baseUrl}/v1/payment/createCustomer`,
  checkoutstatus: ` ${ApiUrls.baseUrl}/v1/payment/billing`,
  planCheckout: `${ApiUrls.baseUrl}/v1/payment/checkout`,
  invoicedetails: `${ApiUrls.baseUrl}/v1/payment/invoiceDetails`,
  //CHART INTEGRATION
  waterfall: `${ApiUrls.baseUrl}/v1/users/waterfallGraph`,
  heatmap: `${ApiUrls.baseUrl}/v1/users/chart2`,
  plotly: `${ApiUrls.baseUrl}/v1/users/chart3`,
  plotly_2: `${ApiUrls.baseUrl}/v1/users/chart3_2`,
  plotlyRetention: `${ApiUrls.baseUrl}/v1/users/cohortChart1`,
  lifetimeCohort: `${ApiUrls.baseUrl}/v1/users/cohortChart2`,
  plotlyRFM: `${ApiUrls.baseUrl}/v1/users/RFMChart`,
  dataSync: `${ApiUrls.baseUrl}/v1/shopify/importShopifyData`,
  noOfImportAndSync: `${ApiUrls.baseUrl}/v1/shopify/numberOfImportsAndSyncs`,
  csvExport: `${ApiUrls.baseUrl}/v1/users/getCSVofCharts`,
  csvExportBoth: `${ApiUrls.baseUrl}/v1/shopify/uploadCSV`,
  statusLast: `${ApiUrls.baseUrl}/v1/shopify/lastSyncStatus`,
  dataDashBoard: `${ApiUrls.baseUrl}/v1/users/dashBoardData`,
  deleteCsv: `${ApiUrls.baseUrl}/v1/shopify/deleteCSVs`,
  delete_account: `${ApiUrls.baseUrl}/v1/users/deleteAccount`,
  unsubscribe_email: `${ApiUrls.baseUrl}/v1/users/unsubscribe`,
  emailNotification: `${ApiUrls.baseUrl}/v1/users/unsubscribeUsingToggle`,
  estimateTime: `${ApiUrls.baseUrl}/v1/shopify/estimateTimeToImport`,
  getDefaultDate: `${ApiUrls.baseUrl}/v1/users/defaultDate`,
  toggledetails: `${ApiUrls.baseUrl}/v1/users/getToggleDetails`,
  pngdownloader: `${ApiUrls.baseUrl}/v1/users/pngExporter`,

  //database update
  chart2UpdateDb:`${ApiUrls.baseUrl}/v1/users/updateDb`,

};

