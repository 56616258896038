/** @format */
import { createResourceId } from "src/utils/create-resource-id";
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from "src/utils/jwt";
import { wait } from "src/utils/wait";
import { users } from "./data";
import axios from "axios";
import { API_URLS } from "src/api-url/API_URLS";

const STORAGE_KEY = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = () => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data);
  } catch (err) {
    // console.error(err);
    return [];
  }
};

const persistUser = (user) => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

class AuthApi {
  async newSignIn(request) {
    const { email, password } = request;

    const options = {
      method: "POST",
      url: API_URLS.sign_in,
      headers: { "Content-Type": "application/json" },
      data: { email, password },
    };

    let response = await axios.request(options);
    // console.log(response.data);
    return response?.data;
  }

  // customer create api integration
  async createCustomer(customerRequest) {
    const { email } = customerRequest;

    const customerOptions = {
      method: "Post",
      url: API_URLS.customercreate,
      headers: { "Content-Type": "application/json" },
      data: { email },
    };
    let response = await axios.request(customerOptions);
    // console.log(response.data);
    return response.data;
  }

  async signIn(request) {
    const { email, password } = request;

    await wait(500);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...users, ...getPersistedUsers()];

        // Find the user
        const user = mergedUsers.find((user) => user.email === email);

        if (!user || user.password !== password) {
          reject(new Error("Please check your email and password"));
          return;
        }

        // Create the access token
        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve({ accessToken });
      } catch (err) {
        // console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async newSignUp(request) {
    const { email, name, password } = request;

    var options = {
      method: "POST",
      url: API_URLS.sign_up,
      headers: { "Content-Type": "application/json" },
      data: { name, email, password },
    };

    let response = await axios.request(options);
   // console.log(response?.data);
    return response.data;
  }
  async verificationMail() {
    var options = {
      method: "POST",
      url: API_URLS.send_email,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("access_token")}`,
      },
    };

    let response = await axios.request(options);
    // console.log(response.data);
    return response.data;
  }

  async signUp(request) {
    const { email, name, password } = request;

    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...users, ...getPersistedUsers()];

        // Check if a user already exists
        let user = mergedUsers.find((user) => user.email === email);

        if (user) {
          reject(new Error("User already exists"));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: undefined,
          email,
          name,
          password,
          plan: "Standard",
        };

        persistUser(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve({ accessToken });
      } catch (err) {
        // console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async newMe(request) {
    const { accessToken } = request;

    var options = {
      method: "GET",
      url: API_URLS.my_details,
      params: {
        accessToken,
      },
      headers: { "Content-Type": "application/json" },
    };

    let response = await axios.request(options);
    return response.data;
  }

  me(request) {
    const { accessToken } = request;

    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const decodedToken = decode(accessToken);

        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...users, ...getPersistedUsers()];

        // Find the user
        const { userId } = decodedToken;
        const user = mergedUsers.find((user) => user.id === userId);

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          plan: user.plan,
        });
      } catch (err) {
        // console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async forgotPassword(request) {
    const { email } = request;

    // console.log(request,"reeeeeeeeeeeeeeeeeeeeeeeee");

    const options = {
      method: "POST",
      url: API_URLS.forgot_password,
      headers: { "Content-Type": "application/json" },
      data: { email },
    };

    let response = await axios.request(options);
    // console.log(response);
    return response.data;
  }

  async resetPassword(request) {
    const { token, password } = request;

    const options = {
      method: "POST",
      url: API_URLS.reset_password,
      headers: { "Content-Type": "application/json" },
      data: { password },
      params: {
        token,
      },
    };

    let response = await axios.request(options);

    return response.data;
  }
}

export const authApi = new AuthApi();
